
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { compareAsc, isSameDay, lightFormat } from "date-fns";

import { EMPTY, PROVIDER, SERVICES as S } from "@/config/literals";

import { ServerType, UserInfos } from "prometheus-synced-ui";
import { LITE_FEATURE, SocietyInfos, UserDetails } from "@/domain";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { ISocietyService, IUserService } from "@/services";

@Component({
  filters: {
    pretty(date: Date): string {
      return lightFormat(date, "dd/MM/yyyy");
    },
  },
  data: () => {
    return {
      EMPTY,
    };
  },
  components: {
    TableUi,
  },
})
export default class UsersTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get userService() {
    return this.container.resolve<IUserService>(S.USER_SERVICE);
  }

  get societyService() {
    return this.container.resolve<ISocietyService>(S.SOCIETY_SERVICE);
  }

  get headers() {
    return [
      {
        text: "Nom et Prénom",
        align: "center",
        sortable: true,
        value: "fullName",
      },
      {
        text: "Société",
        align: "center",
        sortable: true,
        value: "societyId",
      },
      {
        text: "Numéro de Badge",
        align: "center",
        sortable: true,
        value: "badgeNumber",
      },
      {
        text: "Adresse mail (Accès Cloud)",
        align: "center",
        sortable: true,
        value: "email",
      },
      {
        text: "Administrateur",
        align: "center",
        sortable: true,
        value: "isAdministrator",
        width: "12%",
      },
      {
        text: "Utilisateur TechBDD - Gestion de la BDD",
        align: "center",
        sortable: true,
        value: "canAccessDb",
        width: "12%",
      },
      {
        text: "Utilisateur TechBDD - Gestion des RE",
        align: "center",
        sortable: true,
        value: "canAccessExamReport",
        width: "12%",
      },
      {
        text: "Utilisateur application TechMEP",
        align: "center",
        sortable: true,
        value: "canAccessMeasureApp",
        width: "12%",
      },
      {
        text: "Date de départ",
        align: "center",
        sortable: false,
        value: "prettyLeavingDate",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        align: "center",
        visibility: (serverType: ServerType) => LITE_FEATURE.visibility(serverType)
      },
    ];
  }

  get existingBadges() {
    return _.map(this.users, (u) => u.badgeNumber);
  }

  get createComponent() {
    return () => import("@/components/Core/Create/UserCreate.vue");
  }

  get updateComponent() {
    return () => import("@/components/Core/Update/UserUpdate.vue");
  }

  loading: boolean = false;
  users: UserInfos[] = [];
  societies: SocietyInfos[] = [];

  mounted() {
    this.fetch();
  }

  async fetch() {
    this.loading = true;
    try {
      this.users = await this.userService.getMany({
        includeAdministrator: true,
        includeSuperAdministrator: true,
        includeDbUser: true,
        includeExamReportUser: true,
        includeMeasureAppUser: true,
        includeNone: true,
        ids: [],
        searchDate: null,
      });

      this.societies = await this.societyService.getMany({
        includeEnabled: true,
        includeDisabled: true,
        ids: [],
      });
    } finally {
      this.loading = false;
    }
  }

  getSocietyName(societyId: string) {
    const society = _.find(this.societies, (s) => s.id == societyId);

    if (society == undefined) {
      return EMPTY;
    } else {
      return society.name;
    }
  }

  isDisabled(item: UserInfos) {
    return (
      item.leavingDate != null &&
      compareAsc(item.leavingDate, new Date()) < 0 &&
      !isSameDay(item.leavingDate, new Date())
    );
  }

  create(user: UserDetails) {
    this.users.push(user.toInfos());
  }

  update(user: UserDetails) {
    const index = _.findIndex(this.users, (u) => u.id == user.id);
    if (index != -1) {
      this.users.splice(index, 1, user.toInfos());
    }
  }
}
