
import { Component, Vue } from "vue-property-decorator";

import UsersTable from "@/components/Core/Table/UsersTable.vue";

@Component({
  components: {
    UsersTable,
  },
})
export default class Users extends Vue {}
